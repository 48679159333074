import Axios from "axios";
import { Component, useContext, useEffect } from "react";
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";
import { matchPath } from "react-router";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import { toast } from "react-toastify";
import * as player from "./AudioPlayer";
import { context } from "./Context";
import { StationThemeProvider, useStationTheme } from "./Providers/Themes";

import "./Styles/app.scss";
import ChannelRedirect from "Utils/Routing/ChannelRedirect";
import Routes from "Utils/Routing/Routes";
import History from "Utils/Routing/History";
import ContentfulClient from "Utils/ContentfulClient";
import { auth0Initialized } from "react-auth0-spa";
import {
  CONTENTFUL_FREE_LISTENING_LIMIT_ENTRY_ID,
  PLAYER_DEFAULT_STATION_ID,
  // STATION_IDS,
  // STATION_QUERY_CODES,
} from "Utils/Constants";
import JGGlobalStyles from "Pages/Components/Styles/JGGlobalStyles";
import { LocalStorageItem } from "Utils/Constants/LocalStorageItem";
import JGToastContainer from "Pages/Components/JGToastContainer/JGToastContainer";
import { StationContext } from "StationsContext/Context";

let maxListenTime = 0;
let maxListenText =
  "Weekly Free Listening Cap Exceeded. Already Donated? Login to your account. Or, make a donation.";

export { maxListenTime, maxListenText };

let tracker: NodeJS.Timeout;
let listenTime = 0;

function getLocalStorageActiveStation(stations: any, stationsId: any): Station {
  let stationId = localStorage.getItem(LocalStorageItem.activeStationId);

  if (!stationId || !stationsId?.includes(stationId as StationId)) {
    stationId = PLAYER_DEFAULT_STATION_ID;
  }

  return stations?.find((station) => station.id === stationId) as Station;
}

function setLocalStorageActiveStation(stationId: StationId): void {
  localStorage.setItem(LocalStorageItem.activeStationId, stationId);
}

const AppContent = (props: AppState) => {
  const {
    currentSongTitle,
    activeChannel: activeStationIndex,
    channels: STATIONS,
    stationQueryCodes: STATION_QUERY_CODES,
  } = props;
  const { switchTheme } = useStationTheme();

  useEffect(() => {
    switchTheme(STATIONS[activeStationIndex].id);
  }, [switchTheme, activeStationIndex, STATIONS]);

  const getRoute = () => {
    const path = `/:channel(${STATION_QUERY_CODES.join("|")})`;
    const match = matchPath<any>(window.location.pathname, {
      path,
      exact: true,
      strict: false,
    });

    if (match !== null) {
      let channel: StationId = match.params.channel;

      return channel !== STATIONS[activeStationIndex]?.queryCode ? (
        <Redirect to="/" />
      ) : (
        <Route path={path} />
      );
    }
    return null;
  };

  return (
    <div className="app">
      <Helmet>
        <title>
          {currentSongTitle ? currentSongTitle + " - " : ""}
          Jazz Groove
        </title>
      </Helmet>

      <JGToastContainer />

      <Router history={History}>
        <Switch>
          {getRoute()}
          <ChannelRedirect channel={STATIONS[activeStationIndex]?.queryCode} />
        </Switch>
        <Routes />
      </Router>
    </div>
  );
};

class App extends Component<Record<string, unknown>, AppState> {
  // Timer for checking for app updates.
  updateInterval: any;
  STATIONS: any;
  STATION_QUERY_CODES: any;

  constructor(props: any) {
    super(props);

    this.STATIONS = props.stationContext.stations;

    this.STATION_QUERY_CODES = props.stationContext.stationQueryCodes;
    const stationId = props.stationContext.stationsId;
    const storedStation = getLocalStorageActiveStation(
      this.STATIONS,
      stationId
    );
    const storedStationIndex = this.STATIONS.indexOf(storedStation);
    let paramStationQueryCode = new URLSearchParams(window.location.search).get(
      "channel"
    );
    
    const isFreeTrial =
      window.location.pathname.slice(1) === "free" ||
      window.location.pathname.slice(1) === "freetrial";
    if (isFreeTrial) {
      paramStationQueryCode = "freetrial";
      if (new URLSearchParams(window.location.search).get("autoPlay") === "true") {
        localStorage.setItem("needsAutoPlay","true");
      }
    }

    const paramStationIndex = paramStationQueryCode
      ? this.STATION_QUERY_CODES.indexOf(paramStationQueryCode)
      : -1;

    let channelIndex = paramStationIndex;
    if (paramStationIndex < 0) {
      // If there's no station defined in the URL, use the saved station.
      channelIndex = storedStationIndex;
    } else if (paramStationIndex !== storedStationIndex) {
      // Prioritize the station in the URL over the saved station and update the saved station to match.
      setLocalStorageActiveStation(this.STATIONS[paramStationIndex].id);
    }

    this.state = {
      songData: {
        artist: "",
        title: "",
        src: "",
      },
      channels: [...this.STATIONS],
      activeChannel: channelIndex,
      stationQueryCodes: props.stationContext.stationQueryCodes,
      stationsId: props.stationContext.stationsId,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      setActiveChannel: () => {},
      raised: 53000,
      scroll: true,
      recentlyPlayed: [],
      external: false,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      setExternal: () => {},
      popup: false,
      currentSongTitle: "",
      paused: true,
      notifications: [],
      firstLoad: true,
      musicStopped: false,
    };
  }

  checkForUpdate = () => {
    //get latest version
    Axios.get(`/version.txt?timestamp=${new Date().getTime()}`).then(
      (response) => {
        const latestVersion = response.data.split(".");
        const currentVersion = (
          process.env.REACT_APP_CURRENT_VERSION || ""
        ).split(".");

        if (
          latestVersion[0] !== currentVersion[0] &&
          !toast.isActive("refresh")
        ) {
          toast(
            <div className="content">
              <p className="update">
                A new version of this website, JazzGroove.org is now available.
              </p>
              <p>Click “Refresh” to get the new version.</p>
              <div className="buttons">
                <button
                  className="dismiss"
                  onClick={() => {
                    clearInterval(this.updateInterval);
                    this.updateInterval = setInterval(
                      this.checkForUpdate,
                      3600000
                    );
                  }}
                >
                  Later
                </button>
                <button
                  className="refresh"
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  Refresh
                </button>
              </div>
            </div>,
            {
              autoClose: false,
              position: toast.POSITION.BOTTOM_LEFT,
              toastId: "refresh",
            }
          );
        }
      }
    );
  };

  componentDidMount() {
    player.setAudioCallbacks({
      statusUpdate: (e) => {
        if (e.type === "pause") {
          this.setState({ paused: true });
          clearInterval(tracker);
        } else {
          this.setState({ paused: false });
          tracker = setInterval(() => {
            listenTime++;
            if (listenTime === 600) {
              ReactGA.event({
                category: "player",
                action: "5min",
                label: this.state.channels[this.state.activeChannel].channelId,
              });
              /** global dataLayer */
              dataLayer.push({
                event: "listen5mins",
              });
            }
            if (listenTime % 60 === 0) {
              player.incrementMinutes();
              ReactGA.event({
                category: "player",
                action: "stillplaying",
                label: this.state.channels[this.state.activeChannel].channelId,
              });
            }
          }, 1000);
        }
      },
      seeked: (e) => {
        if (
          Math.abs(
            player.audioPlayer.currentTime - (player.lastTimes[0] || 0)
          ) > 0.1
        ) {
          player.setIngoreTimeUpdates(true);
          clearTimeout(player.ignoreTimeUpdateTimeout);
          player.setIgnoreTimeUpdateTimeout(
            setTimeout(() => {
              // 1s delay in allowing new time updates.  If they seek again, we're going right back to the old timestamp.
              player.setIngoreTimeUpdates(false);
            }, 1000)
          );
          player.audioPlayer.pause();
          player.audioPlayer.currentTime = player.lastTimes[0]; // Seek back to where we're supposed to be
          toast.error(
            "Our music licenses do not permit interactivity (skimming forward or backward). Press LISTEN or SKIP SONG to continue listening."
          );
        }
      },
      nowPlayingUpdate: (songData) =>
        this.setState({ songData: songData, currentSongTitle: songData.title }),
      recentlyPlayed: (recentlyPlayed) =>
        this.setState({
          recentlyPlayed: recentlyPlayed.reverse(),
        }),
      musicStopped: (musicStopped) =>
        this.setState({
          musicStopped: musicStopped,
        }),
    });
    this.setActiveChannel(this.state.activeChannel);
    if (process.env.REACT_APP_NODE_ENV !== "dev")
      this.updateInterval = setInterval(this.checkForUpdate, 1800000);
  }

  setActiveChannel = (i) => {
    if (!auth0Initialized) {
      setTimeout(() => this.setActiveChannel(i), 100);
      return;
    }
    if (!player.newPlaylist) {
      this.setState({
        external: true,
      });
      if (i > 1) i = 0;
    } else {
      if (maxListenTime) {
        player.newPlaylist(
          this.state.channels[i].playlistId
            ? this.state.channels[i].playlistId
            : this.state.channels[0].playlistId,
          this.state.channels[i].channelId,
          this.state.external,
          this.state.paused
        );
      } else {
        ContentfulClient.getEntry<any>(CONTENTFUL_FREE_LISTENING_LIMIT_ENTRY_ID)
          .then((entry) => {
            maxListenTime = entry.fields.limit;
            maxListenText = entry.fields.modalText;
            player.newPlaylist(
              this.state.channels[i].playlistId
                ? this.state.channels[i].playlistId
                : this.state.channels[0].playlistId,
              this.state.channels[i].channelId,
              this.state.external,
              this.state.paused
            );
          })
          .catch(() => (maxListenTime = 90));
      }
    }
    this.setState(
      {
        activeChannel: i,
      },
      () => {
        setLocalStorageActiveStation(this.STATIONS[i].id);

        let recentlyPlayed: any = localStorage.getItem(
          LocalStorageItem.recentlyPlayed
        );
        if (recentlyPlayed) {
          recentlyPlayed = JSON.parse(recentlyPlayed);
          this.setState({
            recentlyPlayed: recentlyPlayed.reverse(),
          });
        } else {
          import("./Utils/Constants/RecentlyPlayed").then((RecentlyPlayed) => {
            localStorage.setItem(
              LocalStorageItem.recentlyPlayed,
              JSON.stringify(RecentlyPlayed.default)
            );
            this.setState({
              recentlyPlayed: RecentlyPlayed.default.reverse(),
            });
          });
        }
      }
    );
  };

  setExternal = (value) => {
    this.setState({ external: value });
  };

  render() {
    return (
      <StationThemeProvider>
        <CssBaseline />
        {JGGlobalStyles}
        <context.Provider
          value={{
            ...this.state,
            setActiveChannel: this.setActiveChannel,
            setExternal: this.setExternal,
          }}
        >
          <AppContent {...this.state} />
        </context.Provider>
      </StationThemeProvider>
    );
  }
}

export function AppWrapper() {
  const stationContext = useContext(StationContext);
  player.getStations(stationContext.stations);

  return <>{stationContext && <App stationContext={stationContext} />}</>;
}
