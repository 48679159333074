import { useEffect, useState } from "react";
import contentfulClient from "../Utils/ContentfulClient";

const useGetStationApi = () => {
  const [stations, setStation] = useState<any>();
  const isDev = process.env.REACT_APP_NODE_ENV === "dev";

  const parseStations = (stations: any) => {
    // TODO: REMOVE MAPPING ID AND CHANNELID BEFORE DEPLOYMENT
    let parsedStations = stations?.items.map((item: any) => {
      return {
        ...item.fields,
        id: item.fields.channelId,
        channelId: item.fields.id,
      };
    });

    if (isDev) {
      const filteredArray = parsedStations.filter(
        (el: any) => el.id !== "dev1" && el.id !== "dev2"
      );
      parsedStations = filteredArray;
    }

    const isFreeTrial =
      window.location.pathname.slice(1) === "free" ||
      window.location.pathname.slice(1) === "freetrial";

    if (isFreeTrial) {
      const filteredArray = parsedStations.filter(
        (el: any) => el.queryCode === "freetrial"
      );
      parsedStations = filteredArray;
    } else {
      const filteredArray = parsedStations.filter(
        (el: any) => el.queryCode !== "freetrial"
      );
      parsedStations = filteredArray;
    }

    console.log("Parsed Stations: ", parsedStations);

    parsedStations.sort((a: any, b: any) => a.order - b.order);

    return parsedStations;
  };

  function getLang() {
    if (navigator.languages !== undefined) return navigator.languages[0];
    return navigator.language;
  }

  const getStations = async () => {
    try {
      var isCustomCAP = false;

      // Getting browser locale
      var userLang = getLang();

      if (
        userLang &&
        (userLang.includes("ja-JP") ||
          userLang.includes("en-CA") ||
          userLang.includes("en-AU") ||
          userLang.includes("en-GB") ||
          userLang.includes("fr-FR") ||
          userLang.includes("fr-CA") ||
          userLang.includes("de-DE"))
      )
        isCustomCAP = true;

      console.log("browser lang", userLang);

      const entries = await contentfulClient.getEntries({
        content_type: "channelBackgroundList",
        include: 2,
        locale: isCustomCAP ? userLang : "en-US",
      });

      // console.log(entries);

      setStation(parseStations(entries));
    } catch (err) {
      console.error("Unable to refresh image gallery from network", err);
    }
  };

  useEffect(() => {
    getStations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { stations };
};

export default useGetStationApi;
