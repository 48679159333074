import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Container,
  css,
  GlobalStyles,
  IconButton,
  Link,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import Logo from "../Logo";
import { IoLogoFacebook, IoLogoInstagram } from "react-icons/io5";
import { ReactComponent as IosBadge } from "Assets/badge-apple-app-store.svg";
import { ReactComponent as AndroidBadge } from "Assets/badge-google-play.svg";
import { FOOTER_NAV_ITEMS } from "Utils/Constants/FooterNavItems";
import JGIcon from "Pages/Components/JGIcon";
import CoBrowseCode from "Pages/Components/Footer/CoBrowseCode";
import GoogleTranslate from "Pages/Components/Footer/GoogleTranslate";
import * as S from "Pages/Components/Footer/Footer.styles";

// function ThanksLink({ url, text }) {
//   return (
//     <Link href={url} rel="noopener noreferrer" target="_blank">
//       {text}
//     </Link>
//   );
// }

const appBuildVersion =
  process.env.REACT_APP_NODE_ENV === "production"
    ? null
    : `v${process.env.REACT_APP_CURRENT_VERSION}.${
        process.env.REACT_APP_BUILD_NUMBER || "0000"
      }`;

const Footer = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const navLinkStyles = {
    color: theme.palette.text.secondary,
    textDecoration: "none",
    "&:hover": {
      color: theme.palette.text.primary,
      textDecoration: "none",
    },
  };

  const [visibleFooterHeight, setVisibleFooterHeight] = useState(0);
  const visibleFooterHeightStyles = useMemo(() => {
    return (
      <GlobalStyles
        styles={(theme) => css`
          :root {
            --jg-visible-footer-height: ${visibleFooterHeight}px;
          }
        `}
      />
    );
  }, [visibleFooterHeight]);

  useEffect(() => {
    function scrollListener() {
      let result = 0;
      if (
        window.innerHeight + window.pageYOffset >=
        document.body.offsetHeight - theme.jgComponents.HighlightedFooter.height
      ) {
        result = theme.jgComponents.HighlightedFooter.height;
      }
      setVisibleFooterHeight(result);
    }
    window.addEventListener("scroll", scrollListener);
    scrollListener();
    return () => window.removeEventListener("scroll", scrollListener);
  }, [theme.jgComponents.HighlightedFooter.height]);

  return (
    <>
      {visibleFooterHeightStyles}

      <Box
        className={S.classes.root}
        sx={(theme) => ({
          background: theme.palette.background.paper,
          pt: 6.5,
          pb: `${
            theme.jgComponents.Chatlio.chip.height +
            theme.jgComponents.Chatlio.margin * 2
          }px`,
        })}
      >
        <Container
          component={Stack}
          spacing={4}
          direction={isMobile ? "column" : "row"}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: isMobile ? "center" : undefined,
            textAlign: isMobile ? "center" : "left",
          }}
        >
          <Box
            className="jg-footer__social"
            sx={{
              maxWidth: 268,
            }}
          >
            <Box
              sx={{
                maxWidth: "169px",
                maxHeight: "75px",
                mx: isMobile ? "auto" : 0,
              }}
            >
              <Logo height={"75px"} width={undefined} />
            </Box>
            <Typography variant="body2" sx={{ mb: 2, mt: 3 }}>
              The Foundation to Advance Jazz is a{" "}
              <span style={{ whiteSpace: "nowrap" }}>501(c)(3)</span> nonprofit
              organization.
            </Typography>
            <Stack
              direction={"row"}
              spacing={0.5}
              sx={{ alignItems: "center" }}
            >
              <IconButton
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/TheJazzGroove"
                aria-label="Jazz Groove Facebook"
              >
                <JGIcon is={IoLogoFacebook} />
              </IconButton>
              <IconButton
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/JazzGroove1/"
                aria-label="Jazz Groove Instagram"
              >
                <JGIcon is={IoLogoInstagram} />
              </IconButton>
              <Link
                target="_blank"
                rel="noopener noreferrer"
                href="https://apps.apple.com/us/app/the-jazz-groove/id1359980261"
                aria-label="Jazz Groove iOS App"
              >
                <IosBadge />
              </Link>
              <Link
                target="_blank"
                rel="noopener noreferrer"
                href="https://play.google.com/store/apps/details?id=com.thejazzgroove.radio&amp;hl=en_US&amp;gl=US"
                aria-label="Jazz Groove Android App"
              >
                <AndroidBadge />
              </Link>
            </Stack>
            <Stack
              direction={"row"}
              spacing={0.5}
              sx={{ alignItems: "center" }}
            >
              <GoogleTranslate />
              <Link href="https://www.contentful.com/" rel="nofollow" target="_blank">
                <img src="https://images.ctfassets.net/fo9twyrwpveg/7F5pMEOhJ6Y2WukCa2cYws/398e290725ef2d3b3f0f5a73ae8401d6/PoweredByContentful_DarkBackground.svg" width="100px" alt="Powered by Contentful" />
              </Link>
            </Stack>
          </Box>

          <Stack
            className="jg-footer__nav"
            direction="row"
            sx={{
              display: "flex",
              justifyContent: "center",
              flexWrap: isMobile ? "wrap" : "no-wrap",
              mb: isMobile ? -2 : 0,
            }}
          >
            {FOOTER_NAV_ITEMS.map((category) => {
              return (
                <Box
                  key={category.title}
                  sx={{
                    flex: 1,
                    flexShrink: 0,
                    mx: 2,
                    mb: isMobile ? 2 : 0,
                    minWidth: "6.75rem",
                  }}
                >
                  <Box>
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: "bold",
                        mb: 2,
                        textTransform: "uppercase",
                      }}
                    >
                      {category.title}
                    </Typography>
                  </Box>
                  {category.items.map((nav) => {
                    return (
                      <Box key={nav.text} sx={{ mb: 1 }}>
                        {nav.to ? (
                          <Link
                            component={RouterLink}
                            to={nav.to}
                            target={nav.target}
                            sx={{ ...navLinkStyles }}
                          >
                            {nav.text}
                          </Link>
                        ) : (
                          <Link
                            href={nav.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{ ...navLinkStyles }}
                          >
                            {nav.text}
                          </Link>
                        )}
                      </Box>
                    );
                  })}
                </Box>
              );
            })}
          </Stack>

          <Box
            className="jg-footer__special-thanks"
            sx={{
              maxWidth: 310,
            }}
          >
            <Typography
              variant="body1"
              sx={{ fontWeight: "bold", mb: 2, textTransform: "uppercase" }}
            >
              Co-browse Code
            </Typography>
            <CoBrowseCode />
            <Typography variant="body2">
              <br />
              With your permission, to help assist you when you speak or chat
              with Jazz Groove support, you may share what our website looks
              like on your screen using your co-browse code listed above.
              Nothing else on your computer is shared except for The Jazz Groove
              website.
            </Typography>
          </Box>
        </Container>
      </Box>

      <Box
        className={S.classes.highlightedFooter}
        sx={(theme) => ({
          background: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          height: "var(--jg-highlighted-footer-height)",
          mb: "var(--jg-player-height)",
        })}
      >
        <Container
          component={Stack}
          direction="row"
          sx={(theme) => ({
            ...theme.typography.body2,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            height: "100%",

            [theme.breakpoints.down("sm")]: {
              flexDirection: "column-reverse",
              justifyContent: "center",
            },
          })}
        >
          <Box sx={{ whiteSpace: "nowrap" }}>
            © {new Date().getFullYear()} Foundation to Advance Jazz
          </Box>
          <Box sx={{ whiteSpace: "nowrap" }}>
            {appBuildVersion && (
              <S.HighlightedFooterItem sx={{ color: "text.secondary" }}>
                {appBuildVersion}
              </S.HighlightedFooterItem>
            )}

            <S.HighlightedFooterItem
              component={Link}
              href="https://www.websitepolicies.com/policies/view/VuSlcfN6"
              target="_blank"
            >
              Terms of Use
            </S.HighlightedFooterItem>

            <S.HighlightedFooterItem
              component={(props) => <Link component={RouterLink} {...props} />}
              to="/privacy-policy"
            >
              Privacy Policy
            </S.HighlightedFooterItem>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Footer;
